import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsDividerModule,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconComponent,
  RdsMenuModule,
} from '@rds/angular-components';

import {
  AuthService,
  NotificationsDropdownComponent,
  NotificationsService,
} from '@bookly/shared';

@Component({
  selector: 'bus-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RdsDividerModule,
    NgOptimizedImage,
    NotificationsDropdownComponent,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsHeaderModule,
    RdsDropdownModule,
    RdsButtonModule,
    RouterOutlet,
    RdsIconComponent,
    RdsMenuModule,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDashboardComponent implements OnInit {
  protected readonly authService = inject(AuthService);
  readonly #notificationService = inject(NotificationsService);
  protected readonly user = this.authService.user;

  ngOnInit(): void {
    this.#notificationService.getNotifications().subscribe();
  }
}
