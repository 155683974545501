import { Injectable, inject, signal } from '@angular/core';
import { finalize, forkJoin, tap } from 'rxjs';

import {
  RoomDetailsModel,
  RoomLayoutsModel,
  RoomsApiService,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #isRoomDetailsLoading = signal(false);
  readonly #roomDetails = signal<RoomDetailsModel | null>(null);
  readonly #roomLayouts = signal<RoomLayoutsModel[] | null>(null);

  get roomDetails() {
    return this.#roomDetails.asReadonly();
  }

  get roomLayouts() {
    return this.#roomLayouts.asReadonly();
  }

  get isLoading() {
    return this.#isRoomDetailsLoading.asReadonly();
  }

  public getRoomData(roomId: string) {
    this.#isRoomDetailsLoading.set(true);
    return forkJoin([
      this.#roomsApiService.getRoomDetails(roomId),
      this.#roomsApiService.getLayouts(roomId),
    ]).pipe(
      tap(([roomDetails, roomLayouts]) => {
        this.#roomDetails.set(roomDetails);
        this.#roomLayouts.set(roomLayouts);
      }),
      finalize(() => this.#isRoomDetailsLoading.set(false))
    );
  }
}
