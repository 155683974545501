<p>Room details</p>

@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
} @else {
  <p class="ui-heading-1-bold">Room name: {{ roomDetails()?.roomName }}</p>
  <p class="ui-label-m">
    {{ roomDetails()?.siteName }} {{ roomDetails()?.buildingName }}
    {{ roomDetails()?.buildingNumber }}
  </p>
  <button
    rds-primary-button
    size="l"
    routerLink="../{{ roomDetails()?.roomDexId }}/create">
    Create
  </button>
}
