import { Injectable, inject, signal } from '@angular/core';
import { finalize, tap } from 'rxjs';

import {
  BookingRequestApiService,
  BookingRequestCreateModel,
  BookingRequestDetailsModel,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class BookingRequestService {
  readonly #bookingRequestApiService = inject(BookingRequestApiService);

  readonly #bookingRequestData = signal<BookingRequestDetailsModel | null>(
    null
  );
  readonly #isLoading = signal(false);

  get isLoading() {
    return this.#isLoading.asReadonly();
  }

  get bookingRequestData() {
    return this.#bookingRequestData.asReadonly();
  }

  public createRoomBooking(requestData: BookingRequestCreateModel) {
    this.#isLoading.set(true);
    return this.#bookingRequestApiService.createRoomBooking(requestData).pipe(
      tap(bookingRequest => this.#bookingRequestData.set(bookingRequest)),
      finalize(() => this.#isLoading.set(false))
    );
  }
}
