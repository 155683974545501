@if (isRoomLoading() || isBookingRequestLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
<bus-room-booking-form
  [roomDetails]="roomDetails()"
  [roomLayouts]="roomLayouts()"
  (bookingRequestFormData)="createRoomBooking($event)">
</bus-room-booking-form>
