<rds-header minimalMargins>
  <rds-divider rds-header-start-divider class="native-menu"></rds-divider>
  <rds-header-app-name>
    <img
      class="header-logo"
      ngSrc="assets/bookly-logo.svg"
      width="105"
      height="32"
      alt="bookly-logo" />
  </rds-header-app-name>
  <rds-divider rds-header-end-divider class="native-menu"></rds-divider>\
  <rds-header-navigation-primary>
    <div class="native-menu">
      <div class="flex-row">
        <rds-header-link
          [routerLink]="'/dashboard/rooms'"
          routerLinkActive="rds-link--active">
          Explore Rooms
        </rds-header-link>
        <rds-header-link
          [routerLink]="'/dashboard/bookings'"
          routerLinkActive="rds-link--active">
          My Bookings
        </rds-header-link>
        <!--        ToDO Uncomment when favourites will be ready-->
        <!--        <rds-header-link
          [active]="isActive('favourites')"
          (toggle)="navigate('favourites')">
          Favourites
        </rds-header-link>-->
        <rds-header-link
          [routerLink]="'/admin'"
          routerLinkActive="rds-link--active">
          Admin Panel
        </rds-header-link>
      </div>
    </div>
  </rds-header-navigation-primary>

  <rds-header-actions>
    <bsh-notifications-dropdown></bsh-notifications-dropdown>
    @if (user) {
      <div
        rdsDropdown
        [autoClose]="true"
        placement="bottom-right"
        class="native-menu">
        <rds-avatar class="dropdown-toggle" rdsDropdownToggle trigger="click">
          <img
            rds-avatar-image
            [ngSrc]="user.picture"
            fill
            alt="User avatar"
            referrerpolicy="no-referrer" />
        </rds-avatar>
        <div class="dropdown" *rdsDropdownMenu>
          <span class="dropdown__name">
            {{ user.name }}
          </span>
          <span class="dropdown__email">{{ user.email }}</span>
          <button rds-text-button (click)="authService.logout()">Logout</button>
        </div>
      </div>
    }
    <div
      class="mobile-menu"
      rdsDropdown
      [autoClose]="true"
      placement="bottom-center">
      <button rdsDropdownToggle trigger="click" rds-dropdown-toggle-icon-button>
        <rds-icon namespace="filled" icon="menu"></rds-icon>
      </button>
      <div *rdsDropdownMenu class="hamburger-content">
        <rds-header-link
          [routerLink]="'/dashboard/rooms'"
          routerLinkActive="rds-link--active">
          Explore Rooms
        </rds-header-link>
        <rds-header-link
          [routerLink]="'/dashboard/bookings'"
          routerLinkActive="rds-link--active">
          My Bookings
        </rds-header-link>
        <!--         ToDO Uncomment when favourites will be ready-->
        <!--        <rds-header-link
          [active]="isActive('favourites')"
          (toggle)="navigate('favourites')">
          Favourites
        </rds-header-link>-->
        @if (user) {
          <rds-divider></rds-divider>
          <div class="user-data">
            <span class="user-data__name">
              {{ user.name }}
            </span>
            <span class="user-data__email">{{ user.email }}</span>
            <button
              rds-secondary-button
              size="s"
              (click)="authService.logout()">
              <rds-icon icon="power_standby" namespace="filled" />
              Logout
            </button>
          </div>
        }
      </div>
    </div>
  </rds-header-actions>
</rds-header>
<div class="main-panel">
  <main>
    <router-outlet></router-outlet>
  </main>
</div>
