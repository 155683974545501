import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { RoomsApiService, RoomsListModel } from '@bookly/shared';

@Component({
  selector: 'bus-rooms-list',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './rooms-list.component.html',
  styleUrl: './rooms-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsListComponent implements OnInit {
  #roomService = inject(RoomsApiService);
  rooms = signal<RoomsListModel[]>([]);

  ngOnInit() {
    this.#roomService.getRooms().subscribe(rooms => {
      this.rooms.set(rooms);
    });
  }
}
