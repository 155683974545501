import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bus-booking-request-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './booking-request-confirmation.component.html',
  styleUrl: './booking-request-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRequestConfirmationComponent {
  readonly #bookingRequestService = inject(BookingRequestService);

  readonly bookingRequestData = this.#bookingRequestService.bookingRequestData;
}
