import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  RdsButtonModule,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';
import { switchMap } from 'rxjs';

import { RoomsService } from '../../services/rooms.service';

@Component({
  selector: 'bus-room-details',
  standalone: true,
  imports: [RdsButtonModule, RouterLink, RdsProgressSpinnerModule],
  templateUrl: './room-details.component.html',
  styleUrl: './room-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #destroyRef = inject(DestroyRef);
  protected readonly roomDetails = this.#roomsService.roomDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly isLoading = this.#roomsService.isLoading;

  public ngOnInit() {
    this.#activatedRoute.params
      .pipe(
        switchMap(({ roomId }) => this.#roomsService.getRoomData(roomId)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }
}
