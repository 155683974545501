import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { RdsProgressSpinnerModule } from '@rds/angular-components';
import { switchMap } from 'rxjs';

import {
  BookingRequestBaseModel,
  BookingRequestCreateModel,
} from '@bookly/shared';

import { RoomsService } from '../../../rooms/services/rooms.service';
import { RoomBookingFormComponent } from '../../components/room-booking-form/room-booking-form.component';
import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bus-booking-request-view',
  standalone: true,
  imports: [RoomBookingFormComponent, RdsProgressSpinnerModule],
  templateUrl: './booking-request-view.component.html',
  styleUrl: './booking-request-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRequestViewComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  protected readonly roomDetails = this.#roomsService.roomDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly isRoomLoading = this.#roomsService.isLoading;
  protected readonly isBookingRequestLoading =
    this.#bookingRequestService.isLoading;

  public ngOnInit() {
    if (!this.roomDetails()) {
      this.#activatedRoute.params
        .pipe(
          switchMap(({ roomId }) => this.#roomsService.getRoomData(roomId)),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }

  protected createRoomBooking(requestData: BookingRequestBaseModel) {
    const request = {
      ...requestData,
      roomDexId: this.roomDetails()?.roomDexId,
    } as BookingRequestCreateModel;
    this.#bookingRequestService.createRoomBooking(request).subscribe(() => {
      void this.#router.navigate([
        'dashboard',
        'rooms',
        this.roomDetails()?.roomDexId,
        'confirm',
      ]);
    });
  }
}
